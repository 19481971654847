import { toast } from "react-toastify";

export const removeAllState = async () => {
  await localStorage.removeItem("@sandboxminipos");
  localStorage.removeItem("store_id");
  localStorage.removeItem("store_code");
  localStorage.removeItem("login");
};

export const getDecryptedUser = () => {
  const localStorage =
    window.localStorage.getItem("@sandboxminipos") ?? undefined;
  return JSON.parse(localStorage ?? "{}");
};

export const isAuthenticated = async () => {
  const moment = require("moment");
  const decrypt = await getDecryptedUser();
  if (decrypt && decrypt.Auth) {
    const auth = JSON.parse(decrypt.Auth);
    if (auth && auth.data && auth.data.expired_date) {
      let exp: string = auth.data.expired_date;
      let date_string = exp.split(".");
      if (date_string.length === 2) {
        let exp_date = moment(date_string[0]);
        return exp_date.isAfter(new Date());
      }
    }
  }

  return false;
};

export const getToken = () => {
  let token = "";
  const decrypt = getDecryptedUser();
  if (decrypt && decrypt.Auth) {
    const auth = JSON.parse(decrypt.Auth);
    if (auth && auth.data && auth.data.access_token) {
      token = auth.data.access_token;
    }
  } else {
    window.location.pathname = "/login";
  }

  return token;
};

export const validateEmail = (email: string) => {
  let valid = String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );

  if (valid) {
    return true;
  }

  return false;
};

export const notify = (
  type: "success" | "warning" | "error",
  message: string
) => {
  let result;
  switch (type) {
    case "success":
      result = toast.success(message, {
        position: "top-center",
        autoClose: 4000,
      });
      break;
    case "error":
      result = toast.error(message, {
        position: "top-center",
        autoClose: 4000,
      });
      break;
    case "warning":
      result = toast.warning(message, {
        position: "top-center",
        autoClose: 4000,
      });
      break;
    default:
      result = toast(message, {
        position: "top-center",
        autoClose: 4000,
      });
      break;
  }

  return result;
};

export const formatCurrency = (money: number) => {
  return new Intl.NumberFormat("id-ID", {
    style: "currency",
    currency: "IDR",
    minimumFractionDigits: 0,
  }).format(money);
};

export function generateString(length: number) {
  var result = "";
  var characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  var charactersLength = characters.length;
  for (var i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}

export function localStorageSupport() {
  return "localStorage" in window && window["localStorage"] !== null;
}

export function setExpirySession() {
  if (localStorageSupport()) {
    localStorage["posExpiryTime"] = JSON.stringify({
      exp: ~~(new Date().getTime() / 1000) + 43200,
    });
  }
}

export function checkExpirySession() {
  if (localStorageSupport()) {
    const postExpiryTime = localStorage.getItem("posExpiryTime");
    let postExpiryTimeParsed = null;
    if (postExpiryTime) {
      postExpiryTimeParsed = JSON.parse(postExpiryTime);
    }

    if (postExpiryTimeParsed && postExpiryTimeParsed.exp) {
      if (~~(new Date().getTime() / 1000) > postExpiryTimeParsed.exp) {
        // expired
        localStorage.removeItem("posExpiryTime");
        return true;
      }
      return false;
    }
  }
  return false;
}

export function onlyLettersAndSpaces(str: string) {
  return /^[A-Za-z\s]*$/.test(str);
}

export function onlyAlphaNumeric(str: string) {
  return /^[A-Za-z0-9_]+$/.test(str);
}

export function checkPermission(
  feature_name: string,
  permission: "is_view" | "is_edit" | "is_add" | "is_delete"
) {
  if (localStorageSupport()) {
    const data = getDecryptedUser();

    if (data && data.Auth) {
      const auth = JSON.parse(data.Auth);

      if (auth && auth.data && auth.data.features_and_permissions) {
        let check = auth.data.features_and_permissions.some(
          (f: any) => f.feature_name === feature_name && f[permission] === true //eval(`f.${permission}`) === true todo:check this
        );

        return check;
      }
      return false;
    }
  }
}
