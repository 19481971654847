/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  Box,
  Card,
  CardContent,
  CircularProgress,
  Divider,
  IconButton,
  Popover,
  Stack,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { getList } from "redux/order";

import "moment/locale/id";
import moment from "moment";
import Notif from "../../assets/images/kitchen/Notif-bell.png";
import useRedux from "redux/useRedux";
import { Close } from "@mui/icons-material";

interface Props {
  open: boolean;
  anchorEl: any;
  handleClose: () => void;
}

const NotificationSection = ({ open, anchorEl, handleClose }: Props) => {
  const {
    thunkDispatch,
    storeState: {
      Auth,
      AppOption,
      Notification: { notifications },
    },
  } = useRedux();

  const [search, setSearch] = useState("");
  const [limit, setLimit] = useState(20);
  const [loading, setLoading] = useState(false);
  const [listData, setListData] = useState<any>([]);
  const storeId = localStorage.getItem("store_id");

  const getNotification = () => {
    setLoading(true);
    let dataPayload = {
      status: "NEW_ORDER",
      limit: limit,
      filter: search,
      store_id: storeId ?? AppOption.selected_store.id,
      token: Auth?.data?.access_token ?? "",
    };
    thunkDispatch(getList(dataPayload))
      .unwrap()
      .then((res: any) => {
        if (res) {
          if (res.data && res.data.data) {
            setListData(res.data.data);
          }
        }
      })
      .catch((err: any) => console.log(err))
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    if (storeId) {
      getNotification();
    }
  }, [storeId, notifications]);

  return (
    <>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <Card
          sx={{
            maxHeight: "400px",
            borderRadius: "8px",
          }}
        >
          <Box
            sx={{
              boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
            }}
          >
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              px={2}
              py={2}
            >
              <Typography fontSize="18px" fontWeight={700} color="#464646">
                Notifikasi
              </Typography>
              <IconButton onClick={handleClose} sx={{}}>
                <Close />
              </IconButton>
            </Stack>
          </Box>
          <CardContent
            sx={{
              height: "100%",
              maxHeight: "310px",
              overflow: "auto",
            }}
          >
            <Box sx={{}}>
              {!loading ? (
                <>
                  {listData && listData.length > 0 ? (
                    <>
                      <Stack direction="column" spacing={1} mx={-2}>
                        {listData.map((item: any, i: any) => (
                          <Stack
                            key={i}
                            direction="column"
                            spacing={0.5}
                            px={2}
                            pt={1}
                            sx={{
                              transition: "background-color 0.3s",
                              "&:hover": {
                                backgroundColor: "#ebeced",
                              },
                            }}
                          >
                            <Stack
                              direction="row"
                              alignItems="center"
                              justifyContent="space-between"
                            >
                              <Typography
                                fontSize="16px"
                                fontWeight={600}
                                color="#464646"
                              >
                                {item?.customer_name ?? "-"}
                              </Typography>
                              <Typography fontSize="12px" color="#737373">
                                {item?.payment_date
                                  ? moment(item?.payment_date).format("LT")
                                  : "-"}
                              </Typography>
                            </Stack>
                            <Typography fontSize="14px">
                              No. order: {item?.order_number ?? "-"}
                            </Typography>
                            <Typography fontSize="14px" color="#737373">
                              Pesanan baru masuk, lihat sekarang untuk memulai
                              proses.
                            </Typography>
                            <Divider />
                          </Stack>
                        ))}
                      </Stack>
                    </>
                  ) : (
                    <Stack
                      direction="column"
                      alignItems="center"
                      justifyContent="center"
                      spacing={2}
                      px={2}
                      py={2}
                      mb={2}
                    >
                      <img src={Notif} alt="Notification" />
                      <Stack
                        direction="column"
                        alignItems="center"
                        justifyContent="center"
                        spacing={1}
                      >
                        <Typography
                          fontSize="14px"
                          fontWeight={700}
                          color="#464646"
                        >
                          Tidak Ada Notifikasi Saat Ini
                        </Typography>
                        <Typography fontSize="12px" color="#737373">
                          Kami akan memberi tahu Anda ketika ada yang baru.
                        </Typography>
                      </Stack>
                    </Stack>
                  )}
                </>
              ) : (
                <>
                  <Stack
                    height="250px"
                    width="350px"
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <CircularProgress color="inherit" />
                  </Stack>
                </>
              )}
            </Box>
          </CardContent>
        </Card>
      </Popover>
    </>
  );
};

export default NotificationSection;
