import { createAsyncThunk } from "@reduxjs/toolkit";
import { ChecklistState } from "./types";
import axios from "axios";
import { getToken } from "helper/general";


export interface ChecklistProps {
    order_number?: number;
    order_detail_id?: number;
    isActive?: any;
    status_code?: string;
    token?: any;
}

export const checklistData = createAsyncThunk('checklist/create', async (props: ChecklistProps) => {
    const config = {
        headers: {
            api_key: 'Basic 45cb7ebf9-3dcc-h28s-b056-2522c1eed03e',
            token: getToken()
        },
    };
    
    try {
        const response = await axios.post(
            `${process.env.REACT_APP_API_URL}/kitchen/order/detail/changestatus?order_number=${props.order_number}&order_detail_id=${props.order_detail_id}`,
            {
                is_active: props.isActive,
                status_code: props.status_code
            },
            config
        );
        
        if (response && response.data) {
            return {
                data: response.data,
                status: "success",
                error: null,
            } as unknown as ChecklistState;
        } else {
            return {
                data: response.data?.message,
                status: "empty",
                error: null,
            } as unknown as ChecklistState;
        } 
    } catch (err) {
        if (err)
            return {
                data: "Something went wrong!",
                status: "error",
                error: err,
            } as unknown as ChecklistState;
    }
})
