/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  Button,
  FormControl,
  FormHelperText,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { AuthData, AuthLogin, AuthLoginProps } from "redux/auth";
import { notify, setExpirySession, validateEmail } from "helper/general";
import { selectStore } from "redux/appOption/appoption.reducer";
import { useNavigate } from "react-router-dom";
import { useState } from "react";

import moment from "moment";
import useRedux from "redux/useRedux";
import MainCard from "components/Cards/MainCard";
import LoginPic from "../../assets/images/kitchen/login-banner.png";
import "../Auth/styles.css";

const LoginPages = () => {
  const navigate = useNavigate();
  const { thunkDispatch } = useRedux();

  const [status, setStatus] = useState<any>("");
  const [submit, setSubmit] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [profile_data, setProfileData] = useState<AuthData>();
  const [payload, setPayload] = useState<AuthLoginProps>({
    email: "",
    password: "",
  });

  const handleClickShowPassword = () => {
    setShowPassword((prev) => !prev);
  };

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    event.preventDefault();
  };

  const msg = "login sukses";

  const handleLogin = (e: any) => {
    e.preventDefault();
    setSubmit(true);
    setLoading(true);
    if (payload.email && validateEmail(payload.email) && payload.password) {
      thunkDispatch(AuthLogin(payload))
        .unwrap()
        .then((res) => {
          if (res && res.status === "success") {
            notify("success", "Login Sukses");
            setStatus("success");
            setExpirySession();
            setProfileData(res.data);
            localStorage.setItem("login", msg);
            localStorage.getItem("token");
            localStorage.getItem("uuid");
            if (res.data && res.data.profile_data) {
              setTimeout(() => {
                setLoading(false);
                navigate("/");
              }, 2000);
            } else {
              setLoading(false);
            }
          } else if (res && res.status === "error") {
            setLoading(false);
            setStatus("error");
            notify("error", res.error.response.data.message);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      setStatus("error");
      setLoading(false);
    }
  };

  // const setDefaultStore = (item: any) => {
  //   if (item) {
  //     let props: { id: number; name: string } = {
  //       id: Number(item.store_id),
  //       name: item.store_name,
  //     };
  //     thunkDispatch(selectStore(props));
  //   }
  //   setSelectStoreModal(false);
  //   setOpen(true);
  // };

  return (
    <MainCard backgroundColor="#f6f9fc">
      <Stack
        direction="row"
        justifyContent="center"
        alignItems="center"
        spacing={4}
        sx={{ px: 5, pt: 5 }}
        className="rootWelcome"
      >
        <Stack
          minHeight="748px"
          direction="column"
          justifyContent="space-between"
          alignItems="center"
        >
          <form onSubmit={handleLogin} noValidate>
            <Stack
              direction="column"
              spacing="48px"
              maxWidth="500px"
              sx={{ px: 3, pt: "94px" }}
              className="welcome"
            >
              <Stack direction="column" spacing={2}>
                <Typography fontSize="40px" fontWeight={700}>
                  Selamat Datang
                </Typography>
                <Typography
                  fontSize="14px"
                  fontWeight={400}
                  color="#737373"
                  textAlign="justify"
                >
                  Masuk ke Kitchen Monitoring untuk memantau status pesanan
                  secara real-time dan mengelola proses dapur dengan lebih
                  efisien. Pastikan setiap pesanan diproses dengan akurat untuk
                  meningkatkan kecepatan dan kualitas layanan pelanggan.
                </Typography>
              </Stack>
              <Stack direction="column" spacing={3}>
                <Stack direction="column" spacing={1}>
                  <Stack direction="row" spacing={0.5}>
                    <InputLabel sx={{ color: "#464646" }}>Email</InputLabel>
                    <Typography color="red">*</Typography>
                  </Stack>
                  <FormControl>
                    <TextField
                      required
                      type="email"
                      placeholder="Masukkan email Anda"
                      onChange={(e: any) =>
                        setPayload({ ...payload, email: e.target.value })
                      }
                      sx={{
                        bgcolor: "white",
                        borderRadius: "8px",
                        "& legend": { display: "none" },
                        "& .MuiOutlinedInput-root": {
                          "& fieldset": {
                            top: 0,
                            borderRadius: "8px",
                            borderColor: "#E6E6E6",
                          },
                          "&:hover fieldset": {
                            borderColor: "#E6E6E6",
                          },
                          "&.Mui-focused fieldset": {
                            borderColor: "#E6E6E6",
                          },
                        },
                      }}
                    />
                    {(submit && !payload.email) ||
                    (submit && !validateEmail(payload.email)) ? (
                      <FormHelperText
                        error={
                          (submit && !payload.email) ||
                          (submit && !validateEmail(payload.email))
                        }
                      >
                        {!payload.email
                          ? "Email wajib diisi"
                          : "Silahkan masukkan email yang valid"}
                      </FormHelperText>
                    ) : null}
                  </FormControl>
                </Stack>
                <Stack direction="column" spacing={1}>
                  <Stack direction="row" spacing={0.5}>
                    <InputLabel sx={{ color: "#464646" }}>
                      Kata Sandi
                    </InputLabel>
                    <Typography color="red">*</Typography>
                  </Stack>
                  <FormControl>
                    <OutlinedInput
                      required
                      type={showPassword ? "text" : "password"}
                      placeholder="Masukkan kata sandi Anda"
                      onChange={(e: any) =>
                        setPayload({ ...payload, password: e.target.value })
                      }
                      sx={{
                        bgcolor: "white",
                        borderRadius: "8px",
                        borderColor: "#E6E6E6",
                        "& legend": { display: "none" },
                        "& fieldset": { top: 0 },
                        "& .MuiOutlinedInput-notchedOutline": {
                          borderRadius: "8px",
                          borderColor: "#E6E6E6",
                        },
                        "&:hover .MuiOutlinedInput-notchedOutline": {
                          borderRadius: "8px",
                          borderColor: "#E6E6E6",
                        },
                        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                          borderRadius: "8px",
                          borderColor: "#E6E6E6",
                        },
                      }}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                            size="small"
                          >
                            {showPassword ? (
                              <Visibility fontSize="small" />
                            ) : (
                              <VisibilityOff fontSize="small" />
                            )}
                          </IconButton>
                        </InputAdornment>
                      }
                    />
                    {submit && !payload.password ? (
                      <FormHelperText error={submit && !payload.password}>
                        Kata sandi wajib diisi
                      </FormHelperText>
                    ) : null}
                  </FormControl>
                </Stack>
                {/* <Stack sx={{ textAlign: "end" }}>
                <Link
                  to="/forgot-password"
                  style={{ color: "#333", textDecoration: "none" }}
                >
                  <Typography fontSize="16px" fontWeight={400}>
                    Lupa Kata Sandi?
                  </Typography>
                </Link>
              </Stack> */}
              </Stack>
              <Stack bgcolor="" width="100%">
                <Button
                  type="submit"
                  disabled={loading}
                  sx={{
                    py: "15px",
                    textAlign: "center",
                    textTransform: "none",
                    bgcolor: "#FFC000",
                    color: "#333",
                    fontSize: "18px",
                    fontWeight: 600,
                    borderRadius: "8px",
                    "&:hover": {
                      color: "#333",
                      backgroundColor: "#FFC000",
                      boxShadow: "0px 0px 20px rgba(0, 0, 0, 0.15)",
                    },
                    "&.Mui-disabled": {
                      color: "#333",
                      backgroundColor: "#9C9D9E",
                    },
                  }}
                >
                  {loading ? "Tunggu Sebentar" : "Login"}
                </Button>
              </Stack>
            </Stack>
          </form>
          <Stack>
            <Typography fontSize="14px" fontWeight={400} color="#737373">
              {moment().year()} © All Reserved by Pagii Ordering
            </Typography>
          </Stack>
        </Stack>
        <Stack className="imageLogin" display={{ lg: "block" }}>
          <img src={LoginPic} alt="Login" />
        </Stack>
      </Stack>
    </MainCard>
  );
};

export default LoginPages;
