import { Box, Typography } from "@mui/material";
import styled from "styled-components";
import moment from "moment";

const FooterSection = () => {
  return (
    <Container>
      <Box sx={{ textAlign: "center", py: 1 }}>
        <Typography fontSize="14px" fontWeight={400} color="#737373">
          {moment().year()} © All Reserved by Pagii Ordering
        </Typography>
      </Box>
    </Container>
  );
};

const Container = styled.div`
  position: sticky;
  width: 100%;
`;

export default FooterSection;
