/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { Box, Stack } from "@mui/material";
import { useState } from "react";
import { onMessageListener } from "../../firebase";
import { updateNotification } from "redux/notification/notification.reducer";

import MainCard from "components/Cards/MainCard";
import NewOrder from "./card/new.order";
import ProcessSection from "./card/process";
import ReadySection from "./card/ready";
import PopupNotif from "components/Notification/PopupNotif";
import useRedux from "redux/useRedux";

export interface INotificationData {
  order_number: string;
  order_type: string;
  table_id: string;
  table_name: string;
  device_type: string;
  payment_date: Date;
  order_detail: string;
  isOpen: boolean;
}

const MonitoringPage = () => {
  const {
    thunkDispatch,
    storeState: {
      Notification: { notifications },
    },
  } = useRedux();
  const [orderStatus, setOrderStatus] = useState("");
  const [notificationList, setNotificationList] = useState<
    Array<INotificationData>
  >([]);

  onMessageListener()
    .then((payload) => {
      console.log(payload, "notif");
      const mapData = {
        ...payload.data,
        order_detail: JSON.parse(payload.data.order_detail),
        isOpen: true,
      };
      setNotificationList((prev) => prev.concat(mapData));
      let prop: { count_notif: number; count_new_order: number } = {
        count_notif: notifications.count_notif
          ? notifications.count_notif + 1
          : 1,
        count_new_order: notifications.count_new_order
          ? notifications.count_new_order
          : 0,
      };
      thunkDispatch(updateNotification(prop));
    })
    .catch((err) => console.log("failed: ", err));

  return (
    <MainCard backgroundColor="#f6f9fc" hasNavbar hasFooter>
      {notificationList.map((el, idx) => (
        <PopupNotif
          open={el.isOpen}
          list={el}
          setOrderStatus={setOrderStatus}
          handleClose={() => {
            let close = notificationList.map((i, k) => {
              if (k === idx) {
                return { ...i, isOpen: false };
              } else {
                return i;
              }
            });
            setNotificationList(close);
          }}
        />
      ))}

      <Box sx={{ width: "100%", py: 3, overflowX: "auto" }}>
        <Stack
          direction="row"
          justifyContent={{ lg: "center", xs: "start" }}
          alignItems="center"
          spacing={3}
          px={2}
          sx={{ minWidth: "100%", whiteSpace: "nowrap", flexShrink: 0 }}
        >
          <Box sx={{ minWidth: "360px" }}>
            <NewOrder
              orderStatus={orderStatus}
              setOrderStatus={setOrderStatus}
            />
          </Box>
          <Box sx={{ minWidth: "360px" }}>
            <ProcessSection
              orderStatus={orderStatus}
              setOrderStatus={setOrderStatus}
            />
          </Box>
          <Box sx={{ minWidth: "360px" }}>
            <ReadySection
              orderStatus={orderStatus}
              setOrderStatus={setOrderStatus}
            />
          </Box>
        </Stack>
      </Box>
    </MainCard>
  );
};

export default MonitoringPage;
