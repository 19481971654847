/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import "./App.css";
import { Routes, Route, Navigate } from "react-router-dom";
import LoginPages from "./views/Auth/login.pages";
import ForgotPasswordPage from "views/Auth/forgotpassword.page";
import ResetPasswordPage from "views/Auth/resetpassword.page";
import MonitoringPage from "views/Monitoring/index";

//implement firebase
import { useState, useEffect } from "react";
import { fetchToken, onMessageListener } from "./firebase";
import { showDetailStore } from "redux/storeinfo";
import useRedux from "redux/useRedux";
import Sound from "assets/sound/notif.mp3";
import { notify } from "helper/general";
import { updateNotification } from "redux/notification/notification.reducer";

const App = () => {
  const [show, setShow] = useState(false);
  const [notification, setNotification] = useState({ title: "", body: "" });
  const {
    thunkDispatch,
    storeState: {
      Auth,
      AppOption,
      Notification: { notifications },
    },
  } = useRedux();

  const getDetailStore = async (id: any) => {
    await thunkDispatch(showDetailStore(id ? Number(id) : 0))
      .unwrap()
      .then((res: any) => {
        if (res && res.status === "success") {
          let dataDetail = res.data.data;
          sessionStorage.setItem("store_code", dataDetail.store_code);
          localStorage.setItem("store_code", dataDetail.store_code);
          fetchToken(dataDetail.store_code, "SUBSCRIBE");
        }
      })
      .catch((error) => console.log(error));
  };

  useEffect(() => {
    //get data from session to unset topic
    let store_code = sessionStorage.getItem("store_code");
    //unsubscribe topic when stay at login page
    if (store_code && window.location.pathname === "/login") {
      fetchToken(store_code, "REMOVETOKEN");
      sessionStorage.clear();
    }

    if (Auth && Auth.data && Auth.data.user_type === "user_merchant") {
      if (
        AppOption &&
        AppOption.selected_store &&
        AppOption.selected_store.id
      ) {
        getDetailStore(AppOption.selected_store.id);
      }
    }
  }, [Auth, AppOption]);

  // useEffect(() => {
  //   onMessageListener()
  //     .then((payload) => {
  //       // setNotification({
  //       //   title: payload.notification.title,
  //       //   body: payload.notification.body,
  //       // });
  //       // console.log(payload);
  //       // setShow(true);
  //       //play sound and show notify
  //       // new Audio(Sound).play();
  //       notify(
  //         "success",
  //         `${payload.notification.title}, ${payload.notification.body}`
  //       );

  //       //show notif red color dot at menu pesanan
  //       let prop: { count_notif: number; count_new_order: number } = {
  //         count_notif: notifications.count_notif
  //           ? notifications.count_notif + 1
  //           : 1,
  //         count_new_order: notifications.count_new_order
  //           ? notifications.count_new_order
  //           : 0,
  //       };
  //       thunkDispatch(updateNotification(prop));
  //     })
  //     .catch((err) => console.log("failed: ", err));
  // });

  return (
    <Routes>
      <Route path="*" element={<Navigate to={"/404"} replace />} />
      <Route path="/login" element={<LoginPages />} />
      <Route path="/forgot-password" element={<ForgotPasswordPage />} />
      <Route path="/password-recovery/:id" element={<ResetPasswordPage />} />
      <Route path="/" element={<MonitoringPage />} />
    </Routes>
  );
};

export default App;
