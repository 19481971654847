import { createAsyncThunk } from "@reduxjs/toolkit";
import { SessionState, SessionPropsLogin } from "./types";
import axios from "axios";

export const getSession = createAsyncThunk(
  "session/session-login",
  async (props: SessionPropsLogin) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/pos/session-cashier`,
        {
          headers: {
            api_key: "Basic 45cb7ebf9-3dcc-h28s-b056-2522c1eed03e",
            token: props.token,
          },
        }
      );

      if (response && response.data) {
        return {
          data: response.data,
          status: "success",
          error: null,
        } as SessionState;
      } else {
        return {
          data: "Wrong email or password",
          status: "error",
          error: null,
        } as unknown as SessionState;
      }
    } catch (err) {
      return {
        data: "Something went wrong!",
        status: "error",
        error: err,
      } as unknown as SessionState;
    }
  }
);
