/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  Box,
  Card,
  CardContent,
  Checkbox,
  CircularProgress,
  Divider,
  Stack,
  Typography,
} from "@mui/material";
import { FC, useEffect, useState } from "react";
import { notify } from "helper/general";
import { getList } from "redux/order";
import { checklistData } from "redux/checklist";

import "moment/locale/id";
import moment from "moment";
import useRedux from "redux/useRedux";
interface ReadyProps {
  orderStatus: string | null;
  setOrderStatus: React.Dispatch<React.SetStateAction<string>>;
}

const ReadySection: FC<ReadyProps> = ({ orderStatus, setOrderStatus }) => {
  const {
    thunkDispatch,
    storeState: { Auth, AppOption },
  } = useRedux();

  const [search, setSearch] = useState("");
  const [limit, setLimit] = useState(100);
  const [sortBy, setSortBy] = useState("DESC");
  const [totalOrder, setTotalOrder] = useState<any>();
  const [listData, setListData] = useState<any>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [checkLoad, setCheckLoad] = useState<any>(false);
  const storeId = localStorage.getItem("store_id");

  const [checked, setChecked] = useState<{
    [key: number]: { selesaiDibuat: boolean; dikirimKeCustomer: boolean };
  }>({});

  const getListReady = () => {
    setLoading(true);
    let dataPayload = {
      filter: search,
      limit: limit,
      sortBy: sortBy,
      status: "READY_TO_PICKUP",
      store_id: storeId ?? AppOption?.selected_store?.id,
      token: Auth?.data?.access_token ?? "",
    };
    thunkDispatch(getList(dataPayload))
      .unwrap()
      .then((res: any) => {
        if (res) {
          if (res.data && res.data.data) {
            setListData(res.data.data);
            setTotalOrder(res.data.meta);
          }
        }
      })
      .catch((err: any) => console.log(err))
      .finally(() => {
        setLoading(false);
      });
  };

  const handleChecklist = (
    isActive: any,
    status_code: string,
    order_number: any,
    order_detail_id: any
  ) => {
    let dataObject = {
      order_number: order_number,
      order_detail_id: order_detail_id,
      isActive: isActive,
      status_code: status_code,
    };

    if (dataObject !== undefined) {
      thunkDispatch(checklistData(dataObject))
        .unwrap()
        .then((res) => {
          if (res && res.status === "success") {
            notify("success", res.data.message);
          }
        })
        .catch((err) => {
          console.log(err.message);
          notify("error", err.message);
        })
        .finally(() => {
          setCheckLoad(false);
          getListReady();
        });
    }
  };

  const handleCheckboxChange = (
    orderDetailId: number,
    orderNumber: any,
    type: "selesaiDibuat" | "dikirimKeCustomer"
  ) => {
    setCheckLoad((prev: any) => ({
      ...prev,
      [orderDetailId]: {
        ...prev[orderDetailId],
        [type]: true,
      },
    }));
    setChecked((prev) => {
      const updatedStatus = {
        ...prev,
        [orderDetailId]: {
          ...prev[orderDetailId],
          [type]: !prev[orderDetailId]?.[type],
        },
      };

      const isActive = updatedStatus[orderDetailId][type];
      const status_code = type === "selesaiDibuat" ? "COMPLETED" : "PROCESS";

      handleChecklist(isActive, status_code, orderNumber, orderDetailId);

      return updatedStatus;
    });
  };

  useEffect(() => {
    if (storeId && (orderStatus === "" || orderStatus === "ready")) {
      getListReady();
    }
  }, [storeId, orderStatus]);

  return (
    <>
      <Card
        sx={{
          height: "674px",
          borderRadius: "16px",
          maxWidth: { xs: "360px", lg: "400px" },
          mr: 2,
        }}
      >
        <Box
          sx={{
            boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
          }}
        >
          <Stack
            direction="row"
            alignItems="center"
            spacing={2}
            paddingY={2}
            paddingLeft={3}
          >
            <Typography fontSize="20px" fontWeight={700}>
              Ready
            </Typography>
            {totalOrder?.total >= 10 ? (
              <Stack
                sx={{
                  px: "5px",
                  py: "2px",
                  borderRadius: "100%",
                  alignItems: "center",
                  justifyContent: "center",
                  bgcolor: "#EEAB00",
                }}
              >
                <Typography fontSize="14px" color="#fff">
                  {totalOrder?.total ?? "0"}
                </Typography>
              </Stack>
            ) : (
              <Stack
                sx={{
                  px: 1,
                  py: "2px",
                  borderRadius: "100%",
                  alignItems: "center",
                  justifyContent: "center",
                  bgcolor: "#EEAB00",
                }}
              >
                <Typography fontSize="14px" color="#fff">
                  {totalOrder?.total ?? "0"}
                </Typography>
              </Stack>
            )}
          </Stack>
        </Box>
        <CardContent
          sx={{
            height: "100%",
            maxHeight: "590px",
            overflow: "auto",
          }}
        >
          {listData.length !== 0 || !loading ? (
            <>
              {listData && listData.length > 0 ? (
                <Stack direction="column" spacing="18px">
                  {listData.map((item: any, i: any) => (
                    <Stack
                      key={i}
                      spacing={2}
                      sx={{
                        py: 2,
                        border: "1px solid",
                        borderRadius: "16px",
                        bgcolor: "#f6f9fc",
                        borderColor: "#DFDFDF",
                        maxWidth: { xs: "330px" },
                        minWidth: { lg: "370px" },
                      }}
                    >
                      <Stack direction="column" paddingX={2} spacing="20px">
                        <Stack direction="row" spacing={1}>
                          {item?.device_type === "MOBILEQR" ? (
                            <Stack
                              sx={{
                                px: "8px",
                                py: "4px",
                                bgcolor: "#09BF63",
                                borderRadius: "4px",
                              }}
                            >
                              <Typography fontSize="14px" color="white">
                                Order Online
                              </Typography>
                            </Stack>
                          ) : item?.device_type === "MINIPOS" ? (
                            <Stack
                              sx={{
                                px: "8px",
                                py: "4px",
                                bgcolor: "#FFC226",
                                borderRadius: "4px",
                              }}
                            >
                              <Typography fontSize="14px" color="white">
                                POS
                              </Typography>
                            </Stack>
                          ) : null}
                          {item?.order_type === "IN STORE PICKUP" ? (
                            <Stack
                              sx={{
                                px: "8px",
                                py: "4px",
                                bgcolor: "#F33C60",
                                borderRadius: "4px",
                              }}
                            >
                              <Typography fontSize="14px" color="white">
                                Pick Up
                              </Typography>
                            </Stack>
                          ) : item?.order_type === "DELIVERY" ? (
                            <Stack
                              sx={{
                                px: "8px",
                                py: "4px",
                                bgcolor: "#84D81A",
                                borderRadius: "4px",
                              }}
                            >
                              <Typography fontSize="14px" color="white">
                                Delivery
                              </Typography>
                            </Stack>
                          ) : item?.order_type === "DINE IN" ? (
                            <Stack
                              sx={{
                                px: "8px",
                                py: "4px",
                                bgcolor: "#FF8946",
                                borderRadius: "4px",
                              }}
                            >
                              <Typography fontSize="14px" color="white">
                                {item?.table_name}
                              </Typography>
                            </Stack>
                          ) : null}
                        </Stack>
                        <Stack
                          sx={{
                            flexDirection: { xs: "column", lg: "row" },
                            alignItems: { xs: "start", lg: "center" },
                            justifyContent: {
                              xs: "start",
                              lg: "space-between",
                            },
                          }}
                        >
                          <Typography fontSize="16px" fontWeight={600}>
                            {item.order_number ?? "-"}
                          </Typography>
                          <Typography
                            fontSize="12px"
                            fontWeight={400}
                            color="#737373"
                          >
                            {item?.payment_date
                              ? moment(item?.payment_date).format(
                                  "dddd, D MMM YYYY HH:mm:ss"
                                )
                              : "-"}
                          </Typography>
                        </Stack>
                        {item.order_detail.map((row: any, i: any) => (
                          <Stack key={i} direction="column" spacing={1}>
                            <Stack
                              direction="row"
                              alignItems="center"
                              justifyContent="space-between"
                            >
                              <Typography fontSize="14px" fontWeight={500}>
                                {row?.product_name ?? "-"}
                              </Typography>
                              <Typography fontSize="14px" color="#737373">
                                x {row?.product_qty ?? "-"}
                              </Typography>
                            </Stack>
                            <Stack
                              direction="row"
                              alignItems="center"
                              spacing={1}
                            >
                              <Typography
                                fontSize="12px"
                                fontWeight={600}
                                color="#737373"
                              >
                                Catatan:
                              </Typography>
                              <Typography fontSize="12px" color="#737373">
                                {row?.notes === "" ? "-" : row?.notes}
                              </Typography>
                            </Stack>
                            <Stack direction="column" spacing={0.5}>
                              <Stack
                                direction="row"
                                alignItems="center"
                                justifyContent="space-between"
                                height="16px"
                              >
                                <Typography fontSize="12px" fontWeight={500}>
                                  Selesai Dibuat
                                </Typography>
                                <Checkbox
                                  checked
                                  disabled
                                  sx={{
                                    "& .MuiSvgIcon-root": {
                                      fontSize: 14,
                                    },
                                    marginRight: -1,
                                  }}
                                />
                              </Stack>
                              <Typography fontSize="12px" color="#737373">
                                {row.order_status_detail &&
                                  row.order_status_detail.map((i: any) =>
                                    i.code === "COMPLETED" && i.is_active
                                      ? moment
                                          .utc(i.created_at)
                                          .local()
                                          .format("dddd, D MMM YYYY HH:mm:ss")
                                      : null
                                  )}
                              </Typography>
                            </Stack>
                            <Stack direction="column" spacing={0.5}>
                              <Stack
                                direction="row"
                                alignItems="center"
                                justifyContent="space-between"
                                height="16px"
                              >
                                <Typography fontSize="12px" fontWeight={500}>
                                  Dikirim ke Customer
                                </Typography>
                                {!checkLoad[row.id]?.dikirimKeCustomer ? (
                                  <Checkbox
                                    checked={
                                      checked[row.id]?.dikirimKeCustomer ||
                                      row.order_status_detail?.find(
                                        (status: any) =>
                                          status.code === "PROCESS"
                                      )?.is_active ||
                                      false
                                    }
                                    onChange={() =>
                                      handleCheckboxChange(
                                        row.id,
                                        item.order_number,
                                        "dikirimKeCustomer"
                                      )
                                    }
                                    sx={{
                                      "& .MuiSvgIcon-root": { fontSize: 14 },
                                      marginRight: -1,
                                    }}
                                  />
                                ) : (
                                  <CircularProgress
                                    size="14px"
                                    sx={{ color: "#FFC226" }}
                                  />
                                )}
                              </Stack>
                              <Typography fontSize="12px" color="#737373">
                                {row.order_status_detail &&
                                  row.order_status_detail.map((i: any) =>
                                    i.code === "PROCESS" && i.is_active
                                      ? moment
                                          .utc(i.created_at)
                                          .local()
                                          .format("dddd, D MMM YYYY HH:mm:ss")
                                      : null
                                  )}
                              </Typography>
                            </Stack>

                            {item.order_detail > 1 ? <Divider /> : null}
                          </Stack>
                        ))}
                      </Stack>
                    </Stack>
                  ))}
                </Stack>
              ) : (
                <>
                  <Stack
                    height="100%"
                    direction="row"
                    sx={{
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Typography color="#737373">
                      Belum ada aktivitas pesanan
                    </Typography>
                  </Stack>
                </>
              )}
            </>
          ) : (
            <>
              <Stack
                height="100%"
                direction="row"
                sx={{ justifyContent: "center", alignItems: "center" }}
              >
                <CircularProgress color="inherit" />
              </Stack>
            </>
          )}
        </CardContent>
      </Card>
    </>
  );
};

export default ReadySection;
