import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  OrderState,
  OrderProps,
  DetailOrderProps,
  DetailOrderState,
} from "./types";
import axios from "axios";

export interface ListOrderProps {
  offset?: any;
  limit?: any;
  filter?: any;
  store_id?: any;
  status?: string;
  token?: any;
  sortBy?: any;
}

export const getList = createAsyncThunk(
  "order/list",
  async (props: ListOrderProps) => {
    const config = {
      headers: {
        api_key: "Basic 45cb7ebf9-3dcc-h28s-b056-2522c1eed03e",
        token: props.token,
      },
    };
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/merchant/orders/list?limit=${
          props.limit ?? 0
        }&offset=${props.offset ?? 0}&filter=${props.filter ?? ""}&sortBy=${
          props.sortBy ?? "DESC"
        }&status_order=${props.status}&store_id=${props.store_id ?? ""}`,
        config
      );
      if (response && response.data) {
        return {
          data: response.data,
          status: "success",
          error: null,
        } as unknown as OrderState;
      } else {
        return {
          data: response.data?.message,
          status: "empty",
          error: null,
        } as unknown as OrderState;
      }
    } catch (err) {
      if (err)
        return {
          data: "Something went wrong!",
          status: "error",
          error: err,
        } as unknown as OrderState;
    }
  }
);

export interface updateOrderProps {
  data: {
    order_number: string;
    status_order: string;
    device_type: string;
  };
  token?: any;
}

export const updateStatus = createAsyncThunk(
  "Order/update",
  async (props: updateOrderProps) => {
    const config = {
      headers: {
        api_key: "Basic 45cb7ebf9-3dcc-h28s-b056-2522c1eed03e",
        token: props.token,
      },
    };
    try {
      const response = await axios.put(
        `${process.env.REACT_APP_API_URL}/merchant/order/changestatus`,
        props.data,
        config
      );
      if (response && response.data) {
        return {
          data: response.data,
          status: "success",
          error: null,
        } as unknown as OrderState;
      } else {
        return {
          data: response.data?.message,
          status: "empty",
          error: null,
        } as unknown as OrderState;
      }
    } catch (err) {
      if (err)
        return {
          data: "Something went wrong!",
          status: "error",
          error: err,
        } as unknown as OrderState;
    }
  }
);

export const getOrder = createAsyncThunk(
  "order/list-order",
  async (props: OrderProps) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/pos/get-order-list?offset=${
          props.offset ?? 0
        }&limit=${props.limit ?? 10}&store_id=${props.store_id}${
          props.customer_name ? `&customer_name=${props.customer_name}` : ""
        }${props.order_number ? `&order_number=${props.order_number}` : ""}`,
        {
          headers: {
            api_key: "Basic 45cb7ebf9-3dcc-h28s-b056-2522c1eed03e",
            token: props.token,
          },
        }
      );

      if (response && response.data) {
        return {
          data: response.data,
          status: "success",
          error: null,
        } as OrderState;
      } else {
        return {
          data: response.data?.message,
          status: "error",
          error: null,
        } as unknown as OrderState;
      }
    } catch (err) {
      return {
        data: "Something went wrong!",
        status: "error",
        error: err,
      } as unknown as OrderState;
    }
  }
);

export const getDetailOrder = createAsyncThunk(
  "order/detail-order",
  async (props: DetailOrderProps) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/pos/${props.id}/get-order-detail`,
        {
          headers: {
            api_key: process.env.REACT_APP_API_KEY ?? "",
            token: props.token,
          },
        }
      );

      if (response && response.data) {
        return {
          data: response.data,
          status: "success",
          error: null,
        } as DetailOrderState;
      } else {
        return {
          data: response.data?.message,
          status: "error",
          error: null,
        } as unknown as DetailOrderState;
      }
    } catch (err) {
      return {
        data: "Something went wrong!",
        status: "error",
        error: err,
      } as unknown as DetailOrderState;
    }
  }
);
