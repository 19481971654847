/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import useRedux from "redux/useRedux";
import styled from "styled-components";
import Logo from "../../assets/images/kitchen/Logo.png";
import ConfirmationModal from "components/Confirmation";
import NotificationSection from "components/Notification/NotificationSection";

import { useEffect, useState } from "react";
import { notify, removeAllState } from "helper/general";
import {
  Avatar,
  Badge,
  Box,
  Button,
  Divider,
  IconButton,
  Popover,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import { Logout, NotificationsNone } from "@mui/icons-material";
import { AuthLogout } from "redux/auth";
import { storeInfo } from "redux/storeinfo";
import { fetchToken } from "../../firebase";
import { selectStores } from "redux/appOption/appoption.services";
import { Link } from "react-router-dom";

const Navbar = () => {
  const {
    thunkDispatch,
    storeState: { Auth, AppOption },
  } = useRedux();
  const [storeLogo, setStoreLogo] = useState<any>();
  const [logout, setLogout] = useState<boolean>(false);
  const [invisible, setInvisible] = useState<boolean>(false);
  const [notif, setNotif] = useState(null);
  const [profile, setProfile] = useState(null);
  const openProfile = Boolean(profile);
  const openNotif = Boolean(notif);

  const clickProfile = (event: any) => {
    setProfile(event.currentTarget);
  };

  const clickNotif = (event: any) => {
    setInvisible(true);
    setNotif(event.currentTarget);
  };

  const handleClose = () => {
    setInvisible(false);
    setProfile(null);
    setNotif(null);
  };

  const getStoreInfo = () => {
    const props: { id: any; name: string } = {
      id:
        Auth &&
        Auth.data &&
        Auth.data.profile_data &&
        Auth.data.profile_data.store_access &&
        Auth.data.profile_data.store_access.data_store[0]?.store_id,
      name: "",
    };
    thunkDispatch(selectStores(props))
      .unwrap()
      .then((res: any) => {
        if (res) {
          localStorage.setItem("store_id", res?.selected_store?.id);
          // localStorage.setItem("store_code", res.data.store_code);
        } else {
          console.log("failed");
        }
      });
  };

  const getStoreLogo = (id: number) => {
    thunkDispatch(storeInfo(id))
      .unwrap()
      .then((res: any) => {
        setStoreLogo(res.data.data.store_logo);
      });
  };

  const handleLogout = () => {
    thunkDispatch(AuthLogout())
      .unwrap()
      .then((res) => {
        if (res && res.status === "success") {
          let store_code = sessionStorage.getItem("store_code");
          fetchToken(store_code, "UNSUBSCRIBE");
          sendDataToReactNative();
          removeAllState();
          notify("success", "Kamu telah berhasil keluar dari aplikasi!");
        } else if (res && res.status === "error") {
          if (res.error.response) {
            notify("error", res.error.response.data.message);
          }
        }
      })
      .catch((err) => notify("error", err.message));
  };

  const sendDataToReactNative = () => {
    const msg = "Logout Sukses";
    if (window) {
      postMessage(JSON.stringify(msg));
    }
  };

  useEffect(() => {
    if (Auth && Auth.data) {
      getStoreInfo();
    }
  }, [Auth]);

  useEffect(() => {
    if (AppOption && AppOption.selected_store && AppOption.selected_store.id) {
      getStoreLogo(AppOption.selected_store.id);
    }
  }, [AppOption.selected_store.id]);

  return (
    <Container>
      <NotificationSection
        open={openNotif}
        anchorEl={notif}
        handleClose={handleClose}
      />
      <ConfirmationModal
        open={logout}
        title={"Apakah yakin ingin keluar?"}
        handleConfirm={() => handleLogout()}
        handleClose={() => setLogout(false)}
      />
      <Popover
        open={openProfile}
        anchorEl={profile}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <Box>
          <Stack direction="column" spacing={2} px={2} py={2}>
            <Typography
              fontSize="18px"
              fontWeight={600}
              sx={{ pointerEvents: "none" }}
            >
              {Auth?.data?.profile_data?.full_name}
            </Typography>
            <Divider />
            <Button
              fullWidth
              type="submit"
              sx={{
                boxSizing: "border-box",
                textTransform: "none",
                fontSize: "18px",
                color: "#333",
                "&:hover": {
                  backgroundColor: "#e3e5e6",
                  boxShadow: "0px 0px 20px rgba(0, 0, 0, 0.15)",
                },
              }}
              onClick={() => setLogout(true)}
            >
              Keluar
              <Logout sx={{ marginLeft: "5px", fontSize: "18px" }} />
            </Button>
          </Stack>
        </Box>
      </Popover>
      <Stack
        direction="row"
        justifyContent="space-between"
        paddingX={6}
        paddingY={3}
      >
        <Link to="/">
          <img
            src={Logo}
            alt="Logo Ordering"
            style={{ width: "170px", height: "50px", objectFit: "cover" }}
          />
        </Link>
        <Stack direction="row" spacing={2} alignItems="center">
          <Tooltip title="Notifications">
            <IconButton onClick={clickNotif}>
              <Badge color="warning" variant="dot" invisible={invisible}>
                <NotificationsNone sx={{ color: "#333" }} />
              </Badge>
            </IconButton>
          </Tooltip>
          <Tooltip title="Profile">
            <IconButton onClick={clickProfile}>
              <Avatar
                src=""
                alt="User Logo"
                sx={{ width: "32px", height: "32px" }}
              />
            </IconButton>
          </Tooltip>
        </Stack>
      </Stack>
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  background: #fff;
  position: relative;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
`;

export default Navbar;
