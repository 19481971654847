/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  Card,
  Divider,
  Fade,
  IconButton,
  Modal,
  Stack,
  Typography,
} from "@mui/material";
import { Close } from "@mui/icons-material";
import { ModalStyle } from "helper/style";
import moment from "moment";
import "moment/locale/id";
import { useEffect } from "react";

interface Props {
  open: boolean;
  list: any;
  setOrderStatus: React.Dispatch<React.SetStateAction<string>>;
  handleClose: () => void;
}

const PopupNotif = ({ open, list, setOrderStatus, handleClose }: Props) => {
  useEffect(() => {
    if (open) {
      setOrderStatus("new_order");
    }
  }, [open]);

  if (!open) return null;

  return (
    <>
      <Modal open={open} onClose={handleClose} closeAfterTransition>
        <Fade in={open}>
          <Card sx={ModalStyle}>
            <Stack direction="column" spacing={2} py={2}>
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                px={2}
              >
                <Typography fontSize="20px" fontWeight={700} color="#464646">
                  Pesanan Baru Telah Diterima!
                </Typography>
                <IconButton onClick={handleClose}>
                  <Close />
                </IconButton>
              </Stack>
              <Divider />
              <Stack direction="column" spacing={2} px={2}>
                <Stack direction="row" spacing={1}>
                  {list.device_type === "MOBILEQR" ? (
                    <Stack
                      sx={{
                        px: "8px",
                        py: "4px",
                        bgcolor: "#09BF63",
                        borderRadius: "4px",
                      }}
                    >
                      <Typography fontSize="14px" color="white">
                        Order Online
                      </Typography>
                    </Stack>
                  ) : list.device_type === "MINIPOS" ? (
                    <Stack
                      sx={{
                        px: "8px",
                        py: "4px",
                        bgcolor: "#FFC226",
                        borderRadius: "4px",
                      }}
                    >
                      <Typography fontSize="14px" color="white">
                        POS
                      </Typography>
                    </Stack>
                  ) : null}
                  {list.order_type === "IN STORE PICKUP" ? (
                    <Stack
                      sx={{
                        px: "8px",
                        py: "4px",
                        bgcolor: "#F33C60",
                        borderRadius: "4px",
                      }}
                    >
                      <Typography fontSize="14px" color="white">
                        Pick Up
                      </Typography>
                    </Stack>
                  ) : list.order_type === "DELIVERY" ? (
                    <Stack
                      sx={{
                        px: "8px",
                        py: "4px",
                        bgcolor: "#84D81A",
                        borderRadius: "4px",
                      }}
                    >
                      <Typography fontSize="14px" color="white">
                        Delivery
                      </Typography>
                    </Stack>
                  ) : list.order_type === "DINE IN" ? (
                    <Stack
                      sx={{
                        px: "8px",
                        py: "4px",
                        bgcolor: "#FF8946",
                        borderRadius: "4px",
                      }}
                    >
                      <Typography fontSize="14px" color="white">
                        {list.table_name}
                      </Typography>
                    </Stack>
                  ) : null}
                </Stack>
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Typography fontSize="16px" fontWeight={600}>
                    {list.order_number ?? "-"}
                  </Typography>
                  <Typography fontSize="12px" fontWeight={400} color="#737373">
                    {list?.payment_date
                      ? moment(list?.payment_date).format(
                          "dddd, D MMM YYYY HH:mm:ss"
                        )
                      : "-"}
                  </Typography>
                </Stack>
                {list.order_detail.map((item: any, i: any) => (
                  <>
                    <Stack
                      key={i}
                      direction="row"
                      alignItems="center"
                      justifyContent="space-between"
                      spacing={1}
                    >
                      <Typography fontSize="15px" fontWeight={500}>
                        {item?.product_name ?? "-"}
                      </Typography>
                      <Typography fontSize="14px" color="#737373">
                        x {item?.product_qty ?? "-"}
                      </Typography>
                    </Stack>
                    <Stack direction="row" alignItems="center" spacing={1}>
                      <Typography
                        fontSize="15px"
                        fontWeight={600}
                        color="#737373"
                      >
                        Catatan :
                      </Typography>
                      <Typography fontSize="12px" color="#737373">
                        {item?.notes === "" ? "-" : item?.notes}
                      </Typography>
                    </Stack>
                    <Divider />
                  </>
                ))}
              </Stack>
            </Stack>
          </Card>
        </Fade>
      </Modal>
    </>
  );
};

export default PopupNotif;
