/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  Box,
  Button,
  Card,
  CardContent,
  CircularProgress,
  Divider,
  Stack,
  Typography,
} from "@mui/material";
import { FC, useEffect, useState } from "react";
import { getList, updateStatus } from "redux/order";
import { notify } from "helper/general";

import "moment/locale/id";
import moment from "moment";
import useRedux from "redux/useRedux";
import ConfirmationModal from "components/Confirmation";

interface NewOrderProps {
  orderStatus: string | null;
  setOrderStatus: React.Dispatch<React.SetStateAction<string>>;
}

const NewOrder: FC<NewOrderProps> = ({ orderStatus, setOrderStatus }) => {
  const {
    thunkDispatch,
    storeState: { Auth, AppOption },
  } = useRedux();

  const [search, setSearch] = useState("");
  const [limit, setLimit] = useState(100);
  const [sortBy, setSortBy] = useState("ASC");
  const [totalOrder, setTotalOrder] = useState<any>();
  const [loading, setLoading] = useState(true);
  const [listData, setListData] = useState<any>([]);
  const [orderNumber, setOrderNumber] = useState("");
  const [open, setOpen] = useState(false);
  const storeId = localStorage.getItem("store_id");

  const getListNewOrder = () => {
    setLoading(true);
    let dataPayload = {
      limit: limit,
      filter: search,
      sortBy: sortBy,
      status: "NEW_ORDER",
      store_id: storeId ?? AppOption?.selected_store?.id,
      token: Auth?.data?.access_token ?? "",
    };
    thunkDispatch(getList(dataPayload))
      .unwrap()
      .then((res: any) => {
        if (res) {
          if (res.data && res.data.data) {
            setListData(res.data.data);
            setTotalOrder(res.data.meta);
          }
        }
      })
      .catch((err: any) => console.log(err))
      .finally(() => {
        setLoading(false);
      });
  };

  const handleRefresh = () => {
    setOpen(false);
    setOrderStatus("process");
    getListNewOrder();
  };

  const handleStatus = () => {
    setLoading(true);
    let dataObject = {
      data: {
        order_number: orderNumber,
        status_order: "PROCESS",
        device_type: "KITCHEN",
      },
      token: Auth?.data?.access_token,
    };
    thunkDispatch(updateStatus(dataObject))
      .unwrap()
      .then((res) => {
        if (res && res.status === "success") {
          notify("success", res.data.message);
        }
      })
      .catch((err) => {
        console.log(err.message);
        notify("error", err.message);
      })
      .finally(() => {
        handleRefresh();
      });
  };

  useEffect(() => {
    if (storeId && (orderStatus === "" || orderStatus === "new_order")) {
      getListNewOrder();
    }
  }, [storeId, orderStatus]);

  return (
    <>
      <ConfirmationModal
        open={open}
        handleClose={() => setOpen(false)}
        titleStyle={{ margin: "15px 0" }}
        title={`Apakah anda yakin ingin update status menjadi Process ?`}
        handleConfirm={() => handleStatus()}
      />
      <Card
        sx={{
          height: "674px",
          borderRadius: "16px",
          maxWidth: { xs: "360px", lg: "400px" },
        }}
      >
        <Box
          sx={{
            boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
          }}
        >
          <Stack
            direction="row"
            alignItems="center"
            spacing={2}
            paddingY={2}
            paddingLeft={3}
          >
            <Typography fontSize="20px" fontWeight={700}>
              New Order
            </Typography>
            {totalOrder?.total >= 10 ? (
              <Stack
                sx={{
                  px: "5px",
                  py: "2px",
                  borderRadius: "100%",
                  alignItems: "center",
                  justifyContent: "center",
                  bgcolor: "#EEAB00",
                }}
              >
                <Typography fontSize="14px" color="#fff">
                  {totalOrder?.total ?? "0"}
                </Typography>
              </Stack>
            ) : (
              <Stack
                sx={{
                  px: 1,
                  py: "2px",
                  borderRadius: "100%",
                  alignItems: "center",
                  justifyContent: "center",
                  bgcolor: "#EEAB00",
                }}
              >
                <Typography fontSize="14px" color="#fff">
                  {totalOrder?.total ?? "0"}
                </Typography>
              </Stack>
            )}
          </Stack>
        </Box>
        <CardContent
          sx={{
            height: "100%",
            maxHeight: "590px",
            overflow: "auto",
          }}
        >
          {!loading ? (
            <>
              {listData && listData.length > 0 ? (
                <Stack direction="column" spacing="18px">
                  {listData.map((item: any, i: any) => (
                    <Stack
                      key={i}
                      spacing={2}
                      sx={{
                        py: 2,
                        border: "1px solid",
                        borderRadius: "16px",
                        bgcolor: "#f6f9fc",
                        borderColor: "#DFDFDF",
                        maxWidth: { xs: "330px" },
                        minWidth: { lg: "370px" },
                      }}
                    >
                      <Stack direction="column" paddingX={2} spacing="20px">
                        <Stack direction="row" spacing={1}>
                          {item?.device_type === "MOBILEQR" ? (
                            <Stack
                              sx={{
                                px: "8px",
                                py: "4px",
                                bgcolor: "#09BF63",
                                borderRadius: "4px",
                              }}
                            >
                              <Typography fontSize="14px" color="white">
                                Order Online
                              </Typography>
                            </Stack>
                          ) : item?.device_type === "MINIPOS" ? (
                            <Stack
                              sx={{
                                px: "8px",
                                py: "4px",
                                bgcolor: "#FFC226",
                                borderRadius: "4px",
                              }}
                            >
                              <Typography fontSize="14px" color="white">
                                POS
                              </Typography>
                            </Stack>
                          ) : null}
                          {item?.order_type === "IN STORE PICKUP" ? (
                            <Stack
                              sx={{
                                px: "8px",
                                py: "4px",
                                bgcolor: "#F33C60",
                                borderRadius: "4px",
                              }}
                            >
                              <Typography fontSize="14px" color="white">
                                Pick Up
                              </Typography>
                            </Stack>
                          ) : item?.order_type === "DELIVERY" ? (
                            <Stack
                              sx={{
                                px: "8px",
                                py: "4px",
                                bgcolor: "#84D81A",
                                borderRadius: "4px",
                              }}
                            >
                              <Typography fontSize="14px" color="white">
                                Delivery
                              </Typography>
                            </Stack>
                          ) : item?.order_type === "DINE IN" ? (
                            <Stack
                              sx={{
                                px: "8px",
                                py: "4px",
                                bgcolor: "#FF8946",
                                borderRadius: "4px",
                              }}
                            >
                              <Typography fontSize="14px" color="white">
                                {item?.table_name}
                              </Typography>
                            </Stack>
                          ) : null}
                        </Stack>
                        <Stack
                          sx={{
                            flexDirection: { xs: "column", lg: "row" },
                            alignItems: { xs: "start", lg: "center" },
                            justifyContent: {
                              xs: "start",
                              lg: "space-between",
                            },
                          }}
                        >
                          <Typography fontSize="16px" fontWeight={600}>
                            {item.order_number ?? "-"}
                          </Typography>
                          <Typography
                            fontSize="12px"
                            fontWeight={400}
                            color="#737373"
                          >
                            {item?.payment_date
                              ? moment(item?.payment_date).format(
                                  "dddd, D MMM YYYY HH:mm:ss"
                                )
                              : "-"}
                          </Typography>
                        </Stack>
                        {item.order_detail.map((row: any, i: any) => (
                          <Stack key={i} direction="column" spacing={1}>
                            <Stack
                              direction="row"
                              alignItems="center"
                              justifyContent="space-between"
                            >
                              <Typography fontSize="14px" fontWeight={500}>
                                {row?.product_name ?? "-"}
                              </Typography>
                              <Typography fontSize="14px" color="#737373">
                                x {row?.product_qty ?? "-"}
                              </Typography>
                            </Stack>
                            <Stack
                              direction="row"
                              alignItems="center"
                              spacing={1}
                            >
                              <Typography
                                fontSize="12px"
                                fontWeight={600}
                                color="#737373"
                              >
                                Catatan:
                              </Typography>
                              <Typography fontSize="12px" color="#737373">
                                {row?.notes === "" ? "-" : row?.notes}
                              </Typography>
                            </Stack>
                          </Stack>
                        ))}
                      </Stack>
                      <Divider />
                      <Stack px={2}>
                        <Button
                          type="submit"
                          sx={{
                            py: "12px",
                            fontSize: "14px",
                            fontWeight: 500,
                            textTransform: "none",
                            color: "#333333",
                            bgcolor: "#FFC226",
                            borderRadius: "4px",
                            "&:hover": {
                              color: "#333333",
                              backgroundColor: "#FFC000",
                              boxShadow: "0px 0px 20px rgba(0, 0, 0, 0.15)",
                            },
                          }}
                          onClick={() => {
                            setOpen(true);
                            setOrderNumber(item.order_number);
                          }}
                        >
                          Proses Pesanan
                        </Button>
                      </Stack>
                    </Stack>
                  ))}
                </Stack>
              ) : (
                <>
                  <Stack
                    height="100%"
                    direction="row"
                    sx={{
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Typography color="#737373">
                      Belum ada aktivitas pesanan
                    </Typography>
                  </Stack>
                </>
              )}
            </>
          ) : (
            <>
              <Stack
                height="100%"
                direction="row"
                sx={{ justifyContent: "center", alignItems: "center" }}
              >
                <CircularProgress color="inherit" />
              </Stack>
            </>
          )}
        </CardContent>
      </Card>
    </>
  );
};

export default NewOrder;
