import {
  deleteToken,
  getMessaging,
  getToken,
  onMessage,
} from "firebase/messaging";
import { initializeApp } from "firebase/app";
import { firebaseConfig, pairKey } from "./config-firebase";
import axios from "axios";

const firebaseApp = initializeApp(firebaseConfig);
const messaging = getMessaging(firebaseApp);

export const fetchToken = (topic, condition) => {
  return getToken(messaging, { vapidKey: pairKey })
    .then(async (currentToken) => {
      if (currentToken) {
        // console.log("current token for client: ", currentToken);
        // Track the token -> client mapping, by sending to backend server
        const tokenFromMobile = localStorage.getItem("token");

        // set topic(subscribe) or unset topic(unsubscribe)
        if (condition === "SUBSCRIBE") {
          subscribeTokenToTopic(currentToken, "store" + topic);
          if (tokenFromMobile) {
            subscribeTokenToTopic(tokenFromMobile, "store" + topic);
          }
        }
        if (condition === "UNSUBSCRIBE") {
          unsubscribeTokenFromTopic(currentToken, "store" + topic);
          if (tokenFromMobile) {
            unsubscribeTokenFromTopic(tokenFromMobile, "store" + topic);
          }
        }
        if (condition === "REMOVETOKEN") {
          await deleteToken(messaging);
          console.log("Token Removed");
        }
      } else {
        console.log(
          "No registration token available. Request permission to generate one."
        );
        // shows on the UI that permission is required
      }
    })
    .catch((err) => {
      console.log("An error occurred while retrieving token. ", err);
      // catch error while creating client token
    });
};

export const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      console.log(payload, "receive from firebase.js");
      resolve(payload);
    });
  });

export const subscribeTokenToTopic = (token, topic) => {
  const json = JSON.stringify({
    topic: topic,
    token: token,
  });

  axios
    .post(`${process.env.REACT_APP_API_URL}/firebase/subcribe-topic`, json, {
      method: "POST",
      headers: {
        api_key: `${process.env.REACT_APP_API_KEY}`,
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      if (response.status < 200 || response.status >= 400) {
        // eslint-disable-next-line no-throw-literal
        throw (
          "Error subscribing to topic: " +
          response.status +
          " - " +
          response.text()
        );
      }
    })
    .catch((error) => {
      console.error(error);
    });
};

export const unsubscribeTokenFromTopic = (token, topic) => {
  // fetch('https://iid.googleapis.com/iid/v1/'+token+'/rel/topics/'+topic, {
  //   method: 'DELETE',
  //   headers: new Headers({
  //     // eslint-disable-next-line no-useless-concat
  //     'Authorization': 'key='+`${serverKey}`
  //   })
  // })
  const json = JSON.stringify({
    topic: topic,
    token: token,
  });

  axios
    .post(`${process.env.REACT_APP_API_URL}/firebase/unsubcribe-topic`, json, {
      method: "POST",
      headers: {
        api_key: `${process.env.REACT_APP_API_KEY}`,
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      if (response.status < 200 || response.status >= 400) {
        // eslint-disable-next-line no-throw-literal
        throw (
          "Error unsubscribing to topic: " +
          response.status +
          " - " +
          response.text()
        );
      }
    })
    .catch((error) => {
      console.error(error);
    });
};
