/* eslint-disable react-hooks/exhaustive-deps */
import { checkExpirySession, isAuthenticated } from "helper/general";
import { ReactNode, useEffect } from "react";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";
import { persistor } from "redux/store";
import styled from "styled-components";
import Navbar from "components/Navbar";
import FooterSection from "components/Footer";
import { ToastContainer } from "react-toastify";

export interface Props {
  title?: string;
  meta?: string;
  description?: string;
  backgroundColor?: string;
  children: ReactNode;
  hasNavbar?: boolean;
  hasFooter?: boolean;
}

const MainCard = ({
  title,
  backgroundColor,
  children,
  hasNavbar,
  hasFooter,
}: Props) => {
  const whilelist = [
    "/login",
    "/forgot-password",
    "/404",
    "/password-recovery",
  ];
  const navigate = useNavigate();

  useEffect(() => {
    if (!whilelist.some((x) => window.location.pathname.includes(x))) {
      const check = async () => {
        const auth = await isAuthenticated();
        const expirySession = await checkExpirySession();
        if (expirySession) {
          persistor.pause();
          persistor
            .flush()
            .then(() => {
              return persistor.purge();
            })
            .then(() => {
              navigate("/login");
            });
        }

        if (!auth) {
          navigate("/login");
        }
      };

      check().catch(console.error);
    }
  }, [navigate]);
  return (
    <>
      <Container>
        <Helmet>
          <meta charSet="UTF-8" />
          <meta http-equiv="X-UA-Compatible" content="IE=edge" />
          <meta
            name="viewport"
            content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no"
          />
          <title>{title ?? "Pagii Ordering Kitchen"}</title>
          <link
            rel="stylesheet"
            href="https://cdn.jsdelivr.net/npm/bootstrap@5.1.3/dist/css/bootstrap.min.css"
            integrity="sha384-1BmE4kWBq78iYhFldvKuhfTAU6auU8tT94WrHftjDbrCEXSU1oBoqyl2QvZ6jIW3"
            crossOrigin="anonymous"
          />
          <link
            href="https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,400;0,600;0,700;1,400;1,600;1,700"
            rel="stylesheet"
          />
          <link
            href="https://fonts.cdnfonts.com/css/d-din?styles=37959,37958,37957,37961,37964,37963,37960,37962"
            rel="stylesheet"
          />
          <link
            href="https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,400;0,700;1,400;1,700"
            rel="stylesheet"
          />
        </Helmet>
        <Wrapper backgroundColor={backgroundColor}>
          {hasNavbar && <Navbar />}
          <AppContainer>
            {children}
            {hasFooter && <FooterSection />}
          </AppContainer>
          <ToastContainer />
        </Wrapper>
      </Container>
    </>
  );
};

export const Container = styled.div`
  background-color: #f6f9fc;
  display: block;
`;

export const Wrapper = styled.div<Props>`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  margin: auto;
  background-color: ${(props) => props.backgroundColor ?? "white"};
  font-family: "Ropa Sans";
`;

export const AppContainer = styled.div`
  flex: 1;
`;

export default MainCard;
