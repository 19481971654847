import axios from "axios";
import { getToken } from "helper/general";
import { createAsyncThunk } from "@reduxjs/toolkit";

export interface AppOptionState {
  selected_store: {
    id: number | null;
    name: string;
    code: string;
  };
}

export const initialState: AppOptionState = {
  selected_store: {} as {
    id: number | null;
    name: string;
    code: string;
  },
};

export interface SelectStoreProps {
  id: number | null;
  name: string;
}

export const selectStores = createAsyncThunk(
  "appoption/select/store",
  async ({ id, name }: SelectStoreProps) => {
    const config = {
      headers: {
        api_key: "Basic 45cb7ebf9-3dcc-h28s-b056-2522c1eed03e",
        token: getToken(),
      },
    };
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/merchant/store/${id}`,
        config
      );
      if (response && response.data) {
        return {
          selected_store: {
            id: id,
            name: name,
          },
        } as AppOptionState;
      }
    } catch (err) {
      if (err)
        return {
          data: "Something went wrong!",
          status: "error",
          error: err,
        } as unknown as AppOptionState;
    }
  }
);
